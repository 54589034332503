import { useStaticQuery, graphql } from 'gatsby';

export const useFMKLandingStaticQuery = () => {
  return useLoadFMKLanding();
};

const useLoadFMKLanding = () => {
  return useStaticQuery(graphql`
    query {
      hero: configPagesFestiveMealKits {
        imageHeroText:field_hero {
          value
        }
        showHeroText:field_publish_hero_white_text 
        body:field_hero_description {
          value
        }
        subTitle:field_hero_price {
          value
        }
        title:field_hero_title {
          value
        }
        relationships {
          image:field_hero_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      farmer: configPagesFestiveMealKits {
        title:field_farmer_title
        subTitle:field_farmer_subtitle
        description:field_farmer_description
        link:field_farmer_link {
          uri
          title
        }
        relationships {
          image:field_farmer_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 165) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      cta: configPagesFestiveMealKits {
        linkShopOnline:field_delivery_link {
          uri
          title
        }
        status: field_hide
        title:field_delivery_title
      }
      cards:configPagesFestiveMealKits {
        relationships {
          cards:field_meal_kits {
            title
            subtitle: field_sub_title
            tax:field_mk_tax_rate
            price:field_mk_unit_cost
            earlyBird: field_early_bird_unit_price
            crmId: field_mk_crm_id
            includes:body {
              value
            }
            dateRange: field_early_bird_period {
              end:end_value
              start:value
            }
            points:field_points_earned_
            notice:field_product_fine_print
            dishCount:field_number_of_free_side_dishes
            nid:drupal_internal__nid
            relationships {
            image:field_meal_kit_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 540) {
                     ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      cards2:configPagesFestiveMealKits {
        title:field_signature_2_title
        relationships {
          cards:field_meal_kits_2 {
            title
            tax:field_mk_tax_rate
            price:field_mk_unit_cost
            dateRange: field_early_bird_period {
              end:end_value
              start:value
            }
            earlyBird: field_early_bird_unit_price
            includes:body {
              value
            }
            points:field_points_earned_
            notice:field_product_fine_print
            dishCount:field_number_of_free_side_dishes
            nid:drupal_internal__nid
            crmId: field_mk_crm_id
            relationships {
            image:field_meal_kit_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 540) {
                     ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
      dishes:configPagesFestiveMealKits {
        title:field_dishes_title
      }
    }
  `);
};
