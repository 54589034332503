// Libs.
import React, { useState } from 'react';
// Assets.
import styles from './festive-card.module.scss';
import locale from '../../../locale/locale';
// Components.
import Img from 'gatsby-image';
import CommonPrice from '../price';
import Drawer from '@material-ui/core/Drawer';
import SideFmkCard, {CreateCartObject} from './side-fmk-card';
import { useStateValue } from '../../../store/state';
import { isEmpty, get } from 'lodash';
import { formatFMK } from '../../../libs/formatFromGraph';
import { AddItem } from '../../../store/cart/action-creators';
import navigate from '../../../libs/navigate';

const FestiveCard = ({ card }) => {
  const [state, dispatch] = useStateValue();
  const cart = state.cart.fmk.lineItems;
  const [open, setOpen] = useState(false);
  const { image, title, subtitle, includes, currentPrice: price, notice } = formatFMK(card);
  card.price = price;
  let disabled = false;
  if (!isEmpty(cart)) {
    disabled = !(
      (get(cart[0], 'sides', []).length && card.dishCount) ||
      (!get(cart[0], 'sides', []).length && !card.dishCount)
    );
  }
  const addToCart = () => {
    dispatch(AddItem(product, 'fmk'));
    navigate('/cart');
  };
  const product = CreateCartObject({}, card);
  const {dishCount} = card;
  return (
    <div className={disabled ? styles.festiveCardSectionDisabled : styles.festiveCardSection}>
      <div className={styles.festiveCardImageContainer}>
        {image.fluid && <Img className={styles.festiveCardImage} fluid={image.fluid} />}
      </div>
      <div className={styles.festiveCardInfoContainer}>
        {title && <h3 className={styles.festiveCardTitle}>{title}</h3>}
        {subtitle && <div className={styles.festiveCardTitle}>{subtitle}</div>}
        {includes && (
          <div
            className={styles.festiveCardIncludes}
            dangerouslySetInnerHTML={{ __html: includes }}
          />
        )}
        {price && (
          <div className={styles.festiveCardPrice}>
            <CommonPrice price={price} />
          </div>
        )}
        {notice && (
          <div className={styles.festiveCardNotice} dangerouslySetInnerHTML={{ __html: notice }} />
        )}
        <button
          onClick={() => {
            dishCount ? setOpen(true) : addToCart();
          }}
          disabled={disabled}
          className={styles.festiveCardLink}
        >
          {locale.fmkCard.addtoCart}
        </button>
      </div>
      <Drawer anchor="right" open={open}>
        <div className={styles.fmkConfirmContainer}>
          <button
            type="submit"
            onClick={() => {
              setOpen(false);
            }}
            className={styles.fmkConfirmClose}
          />
          <SideFmkCard card={card} />
        </div>
      </Drawer>
    </div>
  );
};

export default FestiveCard;
