// Libs.
import React, { useState, useEffect } from 'react';
import { useDishesIndex } from '../../../services/dishesIndexService';
import styles from './side-fmk-card.module.scss';
import CommonPrice from '../price';
import useForm from 'react-hook-form';
import { AddItem } from '../../../store/cart/action-creators';
import { useStateValue } from '../../../store/state';
import { values, groupBy, map, isEmpty, get } from 'lodash';
import locale from '../../../locale/locale';
import pageAliases from '../../../data/pageAliases';
import navigate from '../../../libs/navigate';

export const CreateCartObject = (data, card, flow = false) => {
  const dishesFMK = map(groupBy(values(data)), (i, key) => ({ itemId: key, quantity: i.length }));
  const product = {
    itemId: card.crmId,
    quantity: 1,
    sides: dishesFMK,
    flow: flow,
  };
  return product;
};

const SideFmkCardConfirm = ({ card }) => {
  const [state, dispatch] = useStateValue();

  const [initialFMKCartItems, setInitialFMKCartItems] = useState(state.cart.fmk.lineItemsCount);
  const [extra, setExtra] = useState(false);
  const [selects, setSelects] = useState(false);
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    if (!values(data).includes('none')) {
      const product = CreateCartObject(data, card);
      dispatch(AddItem(product, 'fmk'));
      return {};
    }
    return data;
  };
  const AddToCart = (data, extra = false) => {
    const submit = onSubmit(data, extra);
    setExtra(extra);
    if (isEmpty(submit)) {
    } else {
      setSelects(submit);
      return false;
    }
  };
  const checkOut = (data) => {
    AddToCart(data, true);
  };

  useEffect(() => {
    if (state.cart.fmk.lineItemsCount > initialFMKCartItems) {
      navigate(pageAliases.festiveMealKitsAddToOrder, {
        state: { extra },
      });
    }
  }, [state]);

  const dishes = useDishesIndex().getEligible();
  const dishesSelections = [];
  const words = ['one', 'two', 'three', 'four', 'five', 'six', 'seven'];
  const { price } = card;
  for (let i = 0; i < card.dishCount; i++) {
    const name = `dish-${i}`;
    dishesSelections.push(
      <div className={styles.sideFmkCardConfirmSelector} key={i}>
        <select
          name={name}
          ref={register({ required: true })}
          defaultValue={'none'}
          data-error={selects[name] === 'none'}
        >
          <option value="none">-- Select --</option>
          {dishes.map((item, id) => {
            return (
              <option key={id} value={item.crmId}>
                {item.title}
              </option>
            );
          })}
        </select>
        <div
          className={styles.sideFmkCardConfirmFormInputError}
          data-active={selects[name] !== 'none'}
        >
          <span>Fields is a required.</span>
        </div>
      </div>
    );
  }

  return (
    <form className={styles.sideFmkCardConfirmWrapper} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.sideFmkCard}>
        <h2 className={styles.sideFmkCardConfirmTitle}>{locale.fmkCard.confirmDishes}</h2>
        <h3 className={styles.sideFmkCardConfirmSubtitle}>{card.title}</h3>
        <div className={styles.sideFmkCardConfirmSubtitle}>{card.subtitle}</div>
      </div>
      <div className={styles.sideFmkCardConfirmSelections}>
        {card.dishCount > 0 && (
          <div>
            <label className={styles.sideFmkCardConfirmSelectorLabel}>
              {locale.fmkCard.select} {words[card.dishCount - 1]}:
            </label>
            {dishesSelections}
          </div>
        )}
      </div>
      <div className={styles.sideFmkCardConfirmBottom}>
        <div className={styles.sideFmkCardConfirmTotal}>
          <span>{locale.fmkCard.total}</span>
          <CommonPrice price={price} />
        </div>
        <div className={styles.sideFmkCardConfirmButtons}>
          <button
            className={styles.sideFmkCardConfirmAddCart}
            ref={register}
            onClick={handleSubmit(AddToCart)}
          >
            {locale.fmkCard.addtoCart}
          </button>
          <button
            className={styles.sideFmkCardConfirmCheckout}
            ref={register}
            onClick={handleSubmit(checkOut)}
          >
            {locale.fmkCard.checkuot}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SideFmkCardConfirm;
