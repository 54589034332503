// Libs
import React from 'react';
import * as PropTypes from 'prop-types';
import Img from 'gatsby-image';
// Deps.

//Assets.
import styles from './tile.module.scss';

const Tile = ({ image, title, description }) => {
  return (
    <div className={styles.tile}>
      {image && <Img className={styles.tileImage} fluid={image.fluid}/>}
      {title && <h3 className={styles.tileTitle}>{title}</h3>}
      {description && <div className={styles.tileDescription} dangerouslySetInnerHTML={{ __html: description }}/>}
    </div>
  );
};

Tile.defaultProps = {
  image: null,
  title: null,
  description: null,
};

Tile.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Tile;
