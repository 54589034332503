import React from 'react';
import styles from './festive-landing.module.scss';
import FestiveCards from './festive-cards';
import FestiveDishes from './festive-dishes';
import FindStoreCta from '../common/find-store-cta/find-store-cta';
import ImageHero from '../common/image-hero/image-hero';
import MeetFarmer from '../common/meet-farmer/meet-farmer';
import { useDishesIndex } from '../../services/dishesIndexService';
import { cloneDeep, has, get } from 'lodash';
import Layout from '../layout/layout';
import SEO from '../layout/seo';
import {useFMKLandingStaticQuery} from '../../hooks/useFMKLandingStaticQuery.js';
import { formatNode } from '../../libs/formatFromGraph';

const FestiveLanding = () => {
  const data = formatNode(cloneDeep(useFMKLandingStaticQuery()));
  // console.log("::::::::", data);
  const dishes = useDishesIndex().getEligible();
  return (
    <Layout>
      <SEO title="Festive Meal Kits" />
      <div className={styles.festiveLanding}>
        <div className={styles.festiveLandingHeroWrap}>
          <ImageHero data={data.hero}/>
        </div>
        <div className={styles.festiveLandingContainer}>
          <FestiveCards data={data.cards.cards}/>
          <MeetFarmer data={data.farmer}/>
          <div>
            {has(data, 'cards2.title') && data.cards2.title && (
              <h2 className={styles.festiveCardsHeading}>{data.cards2.title}</h2>
            )}
            <FestiveCards data={data.cards2.cards}/>
          </div>
          <div>
            {has(data, 'dishes.title') && data.dishes.title && (
              <h2 className={styles.dishesHeading}>{data.dishes.title}</h2>
            )}
            <FestiveDishes data={dishes}/>
          </div>
          {!get(data, 'cta.status', true) && <FindStoreCta cta={data.cta}/>}
        </div>
      </div>
    </Layout>
  );
};

export default FestiveLanding;
