// Libs
import React from 'react';
import { get } from 'lodash';
// Deps
import Img from 'gatsby-image';

// Assets
import styles from './meet-farmer.module.scss';
import SiteLink from '../site-link';

const MeetFarmer = ({ data }) => {
  const image = get(data, 'image.fluid', null);
  return (
    <div className={styles.meetFarmer}>
      <div className={styles.meetFarmerContainer}>
        <div className={styles.meetFarmerImageWrap}>
          {image && <Img className={styles.meetFarmerImage} fluid={image}/>}
        </div>
        <div className={styles.meetFarmerTextWrap}>
          <div className={styles.meetFarmerTitle}>{data.title}</div>
          <div className={styles.meetFarmerSubtitle}>{data.subTitle}</div>
          <div className={styles.meetFarmerText} dangerouslySetInnerHTML={{ __html: data.description }}/>
          <SiteLink className={styles.meetFarmerLink} href={data.link.uri}>{data.link.title} </SiteLink>
        </div>
      </div>

    </div>
  );
};

export default MeetFarmer;
