// Libs.
import React, { useState } from 'react';
// Assets.
import styles from './festive-dishes.module.scss';
// Components.
import Tiles from '../common/tiles/tiles';
import Tile from '../common/cards/tile';
import locale from '../../locale/locale';

const FestiveDishes = ({ data }) => {
  const [showMobile, setShowMobile] = useState(false);
  return (
    <div className={styles.festiveDishes} data-show={showMobile}>
      <Tiles columns={3}>
        {data && data.map((dish, i) => {
          return (
            <Tile image={dish.image} title={dish.title} description={dish.description} key={i} />
          );
        })}
      </Tiles>
      {!showMobile && data.length > 3 && (
        <div className={styles.tilesListLoadMore}>
          <button
            tabIndex="0"
            className={styles.tilesListLoadMoreLink}
            onClick={() => {
              setShowMobile(true);
            }}
          >
            {locale.loftLandingPage.buttons.loadMoreButton}
          </button>
        </div>
      )}
    </div>
  );
};

export default FestiveDishes;
