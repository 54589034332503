// Libs.
import React from 'react';
import moment from 'moment';
// Assets.
import styles from './festive-cards.module.scss';
// Components.
import Tiles from '../common/tiles/tiles';
import FestiveCard from '../common/cards/festive-card';

const FestiveCards = ({ data }) => {
  return (
    <div className={styles.festiveCards}>
      <Tiles columns={2}>
        {data && data.map((card, i) => {
          return (
            <FestiveCard
              key={i}
              card={card}
            />
          );
        })}
      </Tiles>
    </div>
  );
};

export default FestiveCards;
